<template>
    <div class="radio-toolbar">
        <input type="radio" :checked="content.value == 0.4" id="radioWeb" :name="content.name" v-on:click="$emit('updateInput', content.name, $event.target.value)" value="0.4">
        <label for="radioWeb">Optimised for web</label>

        <input type="radio" :checked="content.value == 1" id="radioBest" :name="content.name" v-on:click="$emit('updateInput', content.name, $event.target.value)" value="1">
        <label for="radioBest">Best quality</label>
    </div>
</template>

<script>
    export default {
        name: "QualitySelector",
        props: {
            content: Object
        },
        data() {
            return {
                value: ''
            }
        },
        emits:
            ['updateInput']
    }
</script>

<style scoped>
    .radio-toolbar {
        margin-bottom: 10px;
    }

    .radio-toolbar input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;
    }

    .radio-toolbar label {
        display: inline-block;
        background-color: #161616;
        padding: 5px 10px;
        font-family: "Open Sans";
        font-size: 12px;
        border: 2px solid #444;
        border-radius: 5px;
        margin-right: 5px;
    }

    .radio-toolbar label:hover {
        background-color: #050505;
    }

    .radio-toolbar input[type="radio"]:checked + label {
        background-color: rgb(128,128,128);
        color: #f2f2f2;
    }
</style>
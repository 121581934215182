<template>
    <div id="wrap">
        <Artboard_FeedCarrousel
                        v-if="template == 'FeedCarrousel'"
                        ref="artboard"
                        v-on:finished="renderFinished"
                        :artboardInitSize="artboardInitSize"
                        :formData="formData" :title="title" />
        <Artboard_AlbumReview v-if="template == 'AlbumReview'"
                        ref="artboard"
                        v-on:finished="renderFinished"
                        :artboardInitSize="artboardInitSize"
                        :formData="formData" :title="title" />
        <Artboard_NewsArticle v-if="template == 'NewsArticle'"
                        ref="artboard"
                        v-on:finished="renderFinished"
                        :artboardInitSize="artboardInitSize"
                        :formData="formData" :title="title" />
        <Artboard_Osmium v-if="template == 'Osmium'"
                        ref="artboard"
                        v-on:finished="renderFinished"
                        :artboardInitSize="artboardInitSize"
                        :formData="formData" :title="title" />
        <Artboard_Giveaway v-if="template == 'Giveaway'"
                        ref="artboard"
                        v-on:finished="renderFinished"
                        :artboardInitSize="artboardInitSize"
                        :formData="formData" :title="title" />
        <Artboard_Quiz v-if="template == 'Quiz'"
                        ref="artboard"
                        v-on:finished="renderFinished"
                        :artboardInitSize="artboardInitSize"
                        :formData="formData" :title="title" />
        <Artboard_Poll v-if="template == 'Poll'"
                       ref="artboard"
                       v-on:finished="renderFinished"
                       :artboardInitSize="artboardInitSize"
                       :formData="formData" :title="title" />
        <Artboard_SpotifyPlaylist v-if="template == 'SpotifyPlaylist'"
                              ref="artboard"
                              v-on:finished="renderFinished"
                              :artboardInitSize="artboardInitSize"
                              :formData="formData" :title="title" />
        <Artboard_AlbumOfTheMonth v-if="template == 'AlbumOfTheMonth'"
                                  ref="artboard"
                                  v-on:finished="renderFinished"
                                  :artboardInitSize="artboardInitSize"
                                  :formData="formData" :title="title" />
        <Artboard_DarkWickpick v-if="template == 'DarkWickpick'"
                               ref="artboard"
                               v-on:finished="renderFinished"
                               :artboardInitSize="artboardInitSize"
                               :formData="formData" :title="title" />
        <Artboard_ZMCafe v-if="template == 'ZMCafe'"
                               ref="artboard"
                               v-on:finished="renderFinished"
                               :artboardInitSize="artboardInitSize"
                               :formData="formData" :title="title" />
    </div>
</template>

<script>
    import Artboard_FeedCarrousel from "@/components/Artboards/Artboard_FeedCarrousel";
    import Artboard_AlbumReview from "@/components/Artboards/Artboard_AlbumReview";
    import Artboard_NewsArticle from "@/components/Artboards/Artboard_NewsArticle";
    import Artboard_Osmium from "@/components/Artboards/Artboard_Osmium";
    import Artboard_Giveaway from "@/components/Artboards/Artboard_Giveaway";
    import Artboard_Quiz from "@/components/Artboards/Artboard_Quiz";
    import Artboard_Poll from "@/components/Artboards/Artboard_Poll";
    import Artboard_SpotifyPlaylist from "@/components/Artboards/Artboard_SpotifyPlaylist";
    import Artboard_AlbumOfTheMonth from "@/components/Artboards/Artboard_AlbumOfTheMonth";
    import Artboard_DarkWickpick from "@/components/Artboards/Artboard_DarkWickpick";
    import Artboard_ZMCafe from "@/components/Artboards/Artboard_ZMCafe";

    export default {
        name: "ImagePreview",
        components: {
            Artboard_FeedCarrousel,
            Artboard_AlbumReview,
            Artboard_NewsArticle,
            Artboard_Osmium,
            Artboard_Giveaway,
            Artboard_Quiz,
            Artboard_Poll,
            Artboard_SpotifyPlaylist,
            Artboard_AlbumOfTheMonth,
            Artboard_DarkWickpick,
            Artboard_ZMCafe
        },
        props: {
            formData: Object,
            template: String,
            title: String,
        },
        data: function () {
            return {
                artboardInitSize: [1]
            }
        },
        mounted: function() {
            fitArtboard();
            this.initArtboardSize();

            window.addEventListener('resize', function () {
                fitArtboard();
            });

            function fitArtboard() {
                var wrap = document.getElementById("wrap");
                var artboard = document.getElementById("artboard");

                var scale = Math.min(wrap.offsetWidth / artboard.scrollHeight, wrap.offsetWidth / artboard.scrollWidth);

                artboard.style.transform = 'scale(' + scale + ')';
                wrap.style.height = (artboard.offsetHeight * scale) + 'px';
                artboard.style.marginTop = ((wrap.offsetHeight - artboard.offsetHeight) / 2) + 'px';
            }
        },
        methods: {
            renderImage() {
                const artboard = document.getElementById("artboard")

                artboard.style.transform = "scale(1)";
                artboard.style.border = "none";
                console.log(artboard);
                this.$refs.artboard.renderImage();
            },
            renderFinished() {
                document.getElementById("artboard").style.border = "2px solid #F2F2F2";
                this.fitArtboard();
            },
            fitArtboard() {
                var wrap = document.getElementById("wrap");
                var artboard = document.getElementById("artboard");

                var scale = Math.min(wrap.offsetWidth / artboard.offsetHeight, wrap.offsetWidth / artboard.offsetWidth);

                artboard.style.transform = 'scale(' + scale + ')';
                wrap.style.height = (artboard.offsetHeight * scale) + 'px';
                artboard.style.marginTop = ((wrap.offsetHeight - artboard.offsetHeight) / 2) + 'px';
            },
            initArtboardSize() {
                const artboard = document.getElementById("artboard");
                this.artboardInitSize[0] = artboard.offsetWidth;
                this.artboardInitSize[1] = artboard.offsetHeight;
            }
        }
    }
</script>

<style scoped>
    #wrap {
        position: relative;
        display: flex;
        justify-content: center;
        align-content: center;
        width: 100%;
    }

    #wrap:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
</style>
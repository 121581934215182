<template>
    <label>
        <input ref="checkbox" class="form-control" type="checkbox"
               v-on:change="$emit('updateInput', content.name, $event.target.checked)"
               v-model="value">
        <span class="checkmark"></span>
        {{ content.label }}
    </label>
</template>

<script>
    export default {
        name: "CheckboxInput",
        props: {
            content: Object
        },
        data() {
            return {
                value: ''
            }
        },
        mounted() {
            if (this.content.checked) {
                this.$refs.checkbox.checked = true
            }
        },
        emits:
            ['updateInput']
    }
</script>

<style scoped>
    input[type='checkbox'] {
        width: inherit;
    }

    label {
        display: block;
        position: relative;
        padding-left: 25px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    label input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 15px;
        width: 15px;
        background-color: #eee;
    }

    /* On mouse-over, add a grey background color */
    label:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    label input:checked ~ .checkmark {
        background-color: #2196F3;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    label input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    label .checkmark:after {
        left: 5px;
        top: 2px;
        width: 4px;
        height: 7px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
</style>
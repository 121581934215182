import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home'
import Editor from '../views/Editor'
import NotFound from '../views/NotFound'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/editor/:artboard',
        name: 'Editor',
        component: Editor
    },
    {
        path: '/:pathMatch(.*)*',
        component: NotFound
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL), routes
})

export default router
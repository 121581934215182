<template>
    <Header :title="template.title" />
    <main>
        <div class="row align-items-center justify-content-center">
            <div id="postPreview" class=" col-md-4 col-sm-5 col-xs-12">
                <ImagePreview ref="imagePreview" :template="template.artboard" :title="template.title" :formData="formData" />
                <div class="saveButton">
                    <GiantButton @clicked="downloadImage" title="Save image" icon="download" />
                </div>
            </div>
            <div id="postDetailsForm" class=" col-md-6 col-sm-6 col-xs-12">
                <Form :form="template.formGroups" v-on:updateFormData="updateFormData" v-on:changeTemplate="changeTemplate"/>
            </div>
        </div>
    </main>
</template>

<script>
    import Header from "@/components/Header";
    import Form from "@/components/Form/Form";
    import ImagePreview from "@/components/ImagePreview";
    import GiantButton from "@/components/GiantButton";
    import TemplateData from '@/templates.json'

    export default {
        name: "editor",
        components: {
            Header,
            ImagePreview,
            GiantButton,
            Form
        },
        data(){
            return{
                templates: TemplateData,
                activeTemplate: "",
                formData: []
            }
        },
        async created() {
            for(var i = 0; i < this.templates.length; i++) {
                if (this.templates[i].artboard == this.$route.params.artboard)
                {
                    this.template = this.templates[i];
                }
            }
        },
        mounted: function() {
            var inputs = document.getElementsByTagName('input');
            var postPreview = document.getElementById("postPreview");

            for (var index = 0; index < inputs.length; ++index) {
                inputs[index].addEventListener('focus', () => {
                    postPreview.classList.add("mobileKeyboard");
                });
                inputs[index].addEventListener('blur', () => {
                    postPreview.classList.remove("mobileKeyboard");
                });
            }
        },
        methods: {
            downloadImage() {
                this.$refs.imagePreview.renderImage();
            },
            updateFormData(formData) {
                this.formData = formData;
            },
            changeTemplate(value) {
                var JSONdata = JSON.parse(value);
                var artboard = document.getElementById("artboard");

                artboard.style.width = JSONdata.x + 'px';
                artboard.style.height = JSONdata.y + 'px';

                artboard.setAttribute("class", "");
                artboard.classList.add(JSONdata.class);
                this.$refs.imagePreview.fitArtboard();
            }
        }
    }
</script>

<style scoped>
    .saveButton {
        margin-top: 15px;
    }

    #postDetailsForm {
        padding: 10px;
    }

    @media screen and (max-width: 767px) {
        #postDetailsForm {
            padding: 7px;
            margin-top: 13px;
            margin-bottom: 100px;
        }

        .row div {
            max-width: 400px;
        }
    }
</style>
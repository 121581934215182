<template>
    <div ref="printMe" id="artboard" class="osmium-1200x1200">

        <div class="background" v-if="getData('backgroundImageAspectRatio', '1.5') <= (getData('artboardX', artboardInitSize[0]) / getData('artboardY', artboardInitSize[1]))"
             :style="{backgroundImage: 'url(\'' + getData('backgroundImage', require('@/assets/img/template-assets/osmiumbackground-placeholder.jpg')) + '\')',
                     backgroundSize: getData('scale', 100) + '%' + ' auto',
                     backgroundPosition: (100 - getData('backgroundXPos', 50)) + '% ' + getData('backgroundYPos', 50) + '%'}">
        </div>

        <div class="background" v-if="getData('backgroundImageAspectRatio', '1.5') > (getData('artboardX', artboardInitSize[0]) / getData('artboardY', artboardInitSize[1]))"
             :style="{backgroundImage: 'url(\'' + getData('backgroundImage', require('@/assets/img/template-assets/osmiumbackground-placeholder.jpg')) + '\')',
                     backgroundSize: 'auto ' + getData('scale', 100) + '%',
                     backgroundPosition: (100 - getData('backgroundXPos', 50)) + '% ' + getData('backgroundYPos', 50) + '%'}">
        </div>

        <div class="osmiumLogo" :style="{backgroundPosition: (getData('logoXPos', 50)) + '% ' + getData('logoYPos', 95) + '%'}"></div>
        <div class="osmiumLogoShadow" :style="{backgroundPosition: (getData('logoXPos', 50)) + '% ' + getData('logoYPos', 95) + '%', opacity: getData('logoShadow', 50) / 100.0}"></div>
    </div>
</template>

<script>
    export default {
        name: "Artboard-Osmium",
        props: {
            formData: Object,
            artboardInitSize: Array
        },
        data() {
            return {
                output: null
            }
        },
        methods: {
            getData(name, placeholder) {
                for(var i =0; i < this.formData.length; i++) {
                    if (this.formData[i][0] == name) {
                        return this.formData[i][1];
                    }
                }

                return placeholder;
            },
            async renderImage() {
                const comp = this;
                this.$html2canvas( this.$refs.printMe, {
                    scale: 1
                }).then((canvas) => {
                    var a = document.createElement('a');
                    const artboard = document.getElementById("artboard");
                    const quality = parseFloat(comp.getData('quality', '0.4'));

                    a.href = canvas.toDataURL( 'image/jpeg', quality);
                    a.download = 'ZM-Osmium' + artboard.offsetWidth + 'x' + artboard.offsetHeight + '-' + (quality * 100) + '_'+ Date.now() + '.jpg';
                    a.click();
                    comp.$emit('finished');
                });
            }
        },
        emits:
            ['finished']
    }
</script>

<style scoped>
    #artboard {
        width: 1200px;
        height: 1200px;
        position:absolute;
        border: 2px solid #F2F2F2;
        overflow: hidden;
    }

    .background {
        position: absolute;
        background-size: cover;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
    }

    .osmiumLogo {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;

        z-index: 10;

        background-size: 845px;
        background-repeat: no-repeat;
        background-position: 50% 92%;
        background-image: url("~@/assets/img/template-assets/Osmium_logo_shade2.png");
    }

    .osmiumLogoShadow {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;

        z-index: 5;

        -webkit-background-size: 300px;
        background-size: 785px;
        background-repeat: no-repeat;
        background-position: 50% 92%;
        background-image: url("~@/assets/img/template-assets/Osmium_logo_shade2-shadow.png");
    }
</style>
<style>
    .osmium-1920x1080 .osmiumLogo,
    .osmium-1920x1080 .osmiumLogoShadow {
        background-size: 1130px!important;
    }

    .osmium-1000x250 .osmiumLogo,
    .osmium-1000x250 .osmiumLogoShadow {
        background-size: 500px;
        background-position: 95% center!important;
    }

    .osmium-624x266 .osmiumLogo,
    .osmium-624x266 .osmiumLogoShadow {
        background-size: 500px;
        background-position: center center!important;
    }
</style>
<template>
    <input class="form-control" type="text"
           v-on:keyup="$emit('updateInput', content.name, $event.target.value)"
           :value="content.value"
           :id="id"
           :placeholder="content.label"
           autocomplete="off"
           :disabled="disabled == true">
</template>

<script>
    export default {
        name: "TextInput",
        props: {
            content: Object,
            text: String,
            disabled: Boolean,
            id: String
        },
        data() {
            return {
                value: ''
            }
        },
        emits:
            ['updateInput']
    }
</script>

<style>

</style>
<template>
    <header>
        <div class="left">
            <router-link to="/">
                <font-awesome-icon id="backButton" class="icon fa-2x" icon="arrow-left" />
            </router-link>
        </div>
        <div class="center">
            <h3>{{ title }}</h3>
        </div>
        <div class="right">
        </div>
    </header>
</template>

<script>
    export default {
        name: "Header",
        props: {
            title: String,
            text: String
        }
    }
</script>

<style scoped>
    header {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 25px;
        align-items: center;
    }

    header .left,
    header .right {
        color: #f2f2f2;
        width: 30px;
    }

    header .left {
        margin-right: 10px;
    }

    header .right {
        margin-left: 10px;
    }

    header .center {
        flex: 1;
    }

    header .center h3 {
        text-align: center;
        font-size: 25px;
        max-width: 400px;
        margin: 0px auto;
    }
</style>
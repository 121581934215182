<template>
    <div class="imageSelector">
        <div class="search">
            <input type="text" class="form-control" id="imageSearchbar" v-model="imageSearch" autocomplete="off" placeholder="Search band, genre or venue...">
            <div class="btn btn-primary" id="search" @click="loadImages(imageSearch)">Search</div>
        </div>
        <div class="box">
            <div id="loading" v-if="loading">
                <LoadingAnimation />
            </div>
            <div v-if="notFound || suggestedKeywords">
                <p class="notFound" v-if="notFound">No photos found :(</p>
                <div id="suggestedKeywords" v-if="suggestedKeywords">
                    <p class="hint">Suggested keywords:</p>
                    <div class="keyword" @click="useKeyword('Concert')">Concert</div>
                    <div class="keyword" @click="useKeyword('Festival')">Festival</div>
                    <div class="keyword" @click="useKeyword('2019')">2019</div>
                    <div class="keyword" @click="useKeyword('Thrash')">Thrash</div>
                    <div class="keyword" @click="useKeyword('Immolation')">Immolation</div>
                    <div class="keyword" @click="useKeyword('Sfeer')">Sfeer</div>
                    <div class="keyword" @click="useKeyword('Crowdsurfer')">Crowdsurfer</div>
                </div>
            </div>
            <div class="images" id="images">
                <ul>
                    <li v-for="item in itemsShown" :key="item.message">
                        <img :src="item[0]" @click="click(item[1])">
                    </li>
                </ul>
            </div>
        </div>
        <label class="fileUpload">
            Click to upload an image
            <input type="file" accept="image/*" @change="onFileChange" style="visibility: hidden;"/>
        </label>
    </div>
</template>

<script>
    import LoadingAnimation from "@/components/LoadingAnimation";

    export default {
        name: "ChooseImage",
        components: {
            LoadingAnimation
        },
        props: {
            content: Object
        },
        data() {
            return {
                url: null,
                itemsAvailable: [],
                itemsShown: [],
                currentShown: 0,
                scrollLock: false,
                imageSearch: "",
                imagesFound: false,
                notFound: false,
                open: false,
                loading: false,
                suggestedKeywords: true
            }
        },
        mounted: function() {
            var comp = this;
            var imageSearchbar = document.getElementById('imageSearchbar');

            imageSearchbar.onkeypress = function(e){
                if (!e) e = window.event;
                var keyCode = e.code || e.key;
                if (keyCode == 'Enter'){
                    // Enter pressed
                    comp.loadImages(imageSearchbar.value);
                    return false;
                }
            }


            const myDiv = document.querySelector('#images')
            myDiv.addEventListener('scroll', () => {
                if (myDiv.scrollTop + myDiv.offsetHeight >= myDiv.scrollHeight - 5) {

                    if (this.scrollLock == false) {
                        this.scrollLock = true;
                        this.loadNextImages();
                    }
                }
            })

        },
        methods: {
            onFileChange(e) {
                const file = e.target.files[0];
                this.url = URL.createObjectURL(file);
                this.$emit('updateInput', this.content.name, this.url);
                this.$emit('updateInput', 'photoCredits', '');
                this.$emit('setPhotoCreditsLocked', false);

                var img = new Image();
                var comp = this;
                img.onload = function() {
                    comp.$emit('updateInput', comp.content.name + 'AspectRatio', img.width / img.height);
                }
                img.src = this.url;
            },
            async click(src) {
                var comp = this;
                var img = new Image(),
                    canvas = document.createElement("canvas"),
                    ctx = canvas.getContext("2d"),
                    srcx = src.replace("http:", "https:");

                img.onload = function() {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    comp.$emit('updateInput', comp.content.name, canvas.toDataURL());
                    comp.$emit('updateInput', 'photoCredits', 'niekvdv');
                    comp.$emit('setPhotoCreditsLocked', true);
                    comp.$emit('updateInput', comp.content.name + 'AspectRatio', img.width / img.height);
                }

                if (/^([\w]+:)?\/\//.test(srcx) && srcx.indexOf(location.host) === -1) {
                    img.crossOrigin = "anonymous"; // or "use-credentials"
                }
                img.src = srcx;
            },
            async loadImages(tags) {
                const comp = this;
                const xhttp = new XMLHttpRequest();
                const invalidTags = ['the', 'and', 'as', 'of', 'to', 'at', 'i', 'in', 'my'];
                const allowedAccounts = ["193807090@N04", "194421284@N04"];

                this.suggestedKeywords = false;
                this.loading = true;

                tags = tags.toLowerCase();

                tags = tags.split(" ");
                var filteredTags = [];

                for (var tag = 0; tag < tags.length; tag++) {
                    if (!invalidTags.includes(tags[tag])) {
                        filteredTags.push(tags[tag]);
                    }
                }

                filteredTags.join();
                tags = filteredTags;

                xhttp.onreadystatechange = function() {
                    comp.itemsAvailable =  [];
                    comp.itemsShown =  [];
                    comp.currentShown =  0;
                    comp.notFound = false;
                    comp.imagesFound = false;

                    if (this.readyState == 4 && this.status == 200) {
                        const result = JSON.parse(this.responseText);
                        comp.loading = false;

                        if (result.photos.photo.length > 0) {
                            comp.imagesFound = true;

                            for(var i = 0; i < result.photos.photo.length; i++) {
                                var obj = result.photos.photo[i];

                                comp.imageID = obj.id;

                                if (allowedAccounts.includes(obj.owner)) {
                                    comp.itemsAvailable.push([obj.url_s, obj.url_k]);
                                }
                            }

                            comp.loadNextImages();
                        } else {
                            comp.notFound = true;
                            comp.suggestedKeywords = true;
                        }
                    }
                }

                xhttp.open("POST", "https://www.flickr.com/services/rest/?method=flickr.photos.search&api_key=a828921bb1c4c6e2127d7da6cc500e5c&extras=url_s,url_k&per_page=250&tags=zmnvdv9y35kmzzp1," + tags + "&tag_mode=all&format=json&nojsoncallback=1&secret=34333e258dd863d7", true);

                xhttp.send();
            },
            loadNextImages() {
                var load = 12;

                for (var i = this.currentShown; i <= this.currentShown + load; i++) {
                    if (i > this.itemsAvailable.length - 1) {
                        break;
                    } else {
                        this.itemsShown.push(this.itemsAvailable[i]);
                        this.scrollLock = false;
                    }
                }

                this.currentShown = this.currentShown + load;
            },
            useKeyword(keyword) {
                this.imageSearch = keyword;
                this.loadImages(keyword);
            }
        },
        emits:
            ['updateInput', 'setPhotoCreditsLocked']
    }
</script>

<style scoped>
    .imageSelector {
        margin-bottom: 10px;
        padding: 5px;
    }

    .imageSelector .search {
        display: flex;
        align-items: center;
    }

    .imageSelector .search input {
        flex-basis: 100%;
        margin-bottom: 0px;
        display: inline-block;
    }

    .imageSelector .search .btn {
        margin-left: 10px;
        display: inline-block;
        height: 28px;
    }

    .imageSelector .box {
        height: 175px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        padding: 8px 5px;
        background-color: rgb(20,20,20);
    }

    .imageSelector .box #suggestedKeywords {
        padding: 20px;
        text-align: center;
    }

    .imageSelector .box #suggestedKeywords p {
        margin-right: 5px;
    }

    .imageSelector .box #suggestedKeywords .keyword {
        display: inline-block;
        padding: 2px 4px;
        border-radius: 5px;
        font-size: 10px;
        background-color: rgb(70,70,70);
        color: rgb(140,140,140);
        margin: 2px;
        font-weight: bold;
        font-family: "Open Sans";
        cursor: pointer;
    }

    .imageSelector .box .notFound {
        color: #9b9b9b;
        text-align: center;
        margin-top: 20px;
    }

    .imageSelector .box .images {
        margin: -3px 0px;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-right: 1px;
        height: 100%;
    }

    .imageSelector .box .images ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none
    }

    .imageSelector .box .images ul::after {
        content: '';
        flex-grow: 1000000000;
    }

    .imageSelector .box .images li {
        height: 120px;
        flex-grow: 1;
        padding: 3px;
    }

    @media screen and (max-width: 872px) {
        .imageSelector .box .images li {
            height: 100px;
        }
    }

    @media screen and (max-width: 400px) {
        .imageSelector .box .images li {
            height: 20vw;
        }
    }

    .imageSelector .box .images img {
        max-height: 100%;
        min-width: 100%;
        object-fit: cover;
        vertical-align: bottom;
        cursor: pointer;
    }

    .imageSelector .fileUpload {
        width: 100%;
        text-align: center;
        font-family: "Open Sans";
        color: #9b9b9b;
        border: 2px dotted #9b9b9b;
        margin-top: 10px;
        cursor: pointer;
        padding: 10px;
        display: inline-block;

    }

    .imageSelector .fileUpload input {
        display: none;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 4px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
</style>
<template>
    <div v-on:click="$emit('clicked')" class="giantBottomButton">
        <p>{{title}} <font-awesome-icon class="icon" :icon="icon"/></p>
    </div>
</template>

<script>
    export default {
        name: "GiantButton",
        props: {
            title: String,
            icon: String
        }
    }
</script>

<style scoped>
    .giantBottomButton {
        background-color: #f2f2f2;
        padding: 20px;
        position: relative;
        bottom: 5px;
        left: 0px;
        right: 0px;
        border-radius: 5px;
        cursor: pointer;
    }

    .giantBottomButton p {
        color: #161616;
        text-align: center;
        font-size: 17px;
        font-weight: bold;
    }

    .giantBottomButton p .icon {
        margin-left: 5px;
        color: #161616;
    }
</style>
<template>
    <LargeHeader />
    <main>
        <div class="cards">
            <Cards :templates="templates" />
        </div>
    </main>
</template>

<script>
    import Cards from "@/components/Cards"
    import LargeHeader from "@/components/LargeHeader";
    import TemplateData from '@/templates.json'

    export default {
        name: "Home",
        components: {
            LargeHeader,
            Cards
        },
        data() {
            return {
                templates: TemplateData
            }
        }
    }
</script>

<style scoped>
    main {
        padding: 10px;
    }

    .cards {
        max-width: 600px;
        margin: 0px auto;
    }
</style>
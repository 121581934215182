<template>
    <div ref="printMe" id="artboard" class="zmcafe-1000x250">
        <img id="beer" src="~@/assets/img/template-assets/zmcafe-beer.png">
        <img id="logo" src="~@/assets/img/template-assets/zmcafe-logo.png">
        <p id="episode">#{{ getData('episode', '1') }}</p>
    </div>
</template>

<script>
    export default {
        name: "Artboard-ZMCafe",
        props: {
            formData: Object,
            artboardInitSize: Array
        },
        data() {
            return {
                output: null
            }
        },
        methods: {
            getData(name, placeholder) {
                for(var i =0; i < this.formData.length; i++) {
                    if (this.formData[i][0] == name) {
                        return this.formData[i][1];
                    }
                }

                return placeholder;
            },
            async renderImage() {
                const comp = this;
                this.$html2canvas( this.$refs.printMe, {
                    scale: 1
                }).then((canvas) => {
                    var a = document.createElement('a');
                    const artboard = document.getElementById("artboard");
                    const quality = parseFloat(comp.getData('quality', '0.4'));

                    a.href = canvas.toDataURL( 'image/jpeg', quality);
                    a.download = 'ZM-Cafe' + artboard.offsetWidth + 'x' + artboard.offsetHeight + '-' + (quality * 100) + '_'+ Date.now() + '.jpg';
                    a.click();
                    comp.$emit('finished');
                });
            }
        },
        emits:
            ['finished']
    }
</script>

<style scoped>
    #artboard {
        width: 1000px;
        height: 250px;
        position:absolute;
        border: 2px solid #F2F2F2;
        overflow: hidden;

        background-image: url('~@/assets/img/template-assets/zmcafe-bg.jpg');
        background-size: cover;
        background-position: center;
    }

    #logo {
        position: absolute;
        right: 15%;
        top: 19%;
        width: 45%;
    }
    
    #beer {
        -webkit-transform: rotate(15deg);
        -moz-transform: rotate(15deg);
        -ms-transform: rotate(15deg);
        -o-transform: rotate(15deg);
        transform: rotate(15deg);

        position: absolute;
        height: 280px;
        top: -10px;
        right: 25px;
    }

    #episode {
        text-align: center;
        position: absolute;
        top: 65%;
        left: 40%;
        right: 15%;
        font-size: 30px;
        font-family: "Gill Sans MT";
        text-transform: uppercase;
        font-weight: 700;
        text-shadow: -3px 5px 5px #000000;
    }
</style>

<style>
    .zmcafe-624x266 #logo {
        left: 15%;
        width: 70%;
        top: 21%;
    }

    .zmcafe-624x266 #episode {
        left: 15%;
        right: 15%;
    }

    .zmcafe-624x266 #beer {
        right: 0px;
        height: 295px;
    }
</style>
<template>
    <div ref="printMe" id="artboard">
        <div class="overlay"></div>

        <div class="content">
            <p class="question">{{getData('question', 'Wat is het beste album van Parkway Drive?')}}</p>
            <ul>
                <li v-if="getData('option1', '') || optionsEdited == false">
                    <img src="~@/assets/img/template-assets/fbicon-like-64x64.png">
                    <p>{{getData('option1', 'IRE')}}</p>
                </li>
                <li v-if="getData('option2', '') || optionsEdited == false">
                    <img src="~@/assets/img/template-assets/fbicon-love-64x64.png">
                    <p>{{getData('option2', 'Reverence')}}</p>
                </li>
                <li v-if="getData('option3', '') || optionsEdited == false">
                    <img src="~@/assets/img/template-assets/fbicon-care-64x64.png">
                    <p>{{getData('option3', 'Deep blue')}}</p>
                </li>
                <li v-if="getData('option4', '') || optionsEdited == false">
                    <img src="~@/assets/img/template-assets/fbicon-haha-64x64.png">
                    <p>{{getData('option4', 'Atlas')}}</p>
                </li>
                <li v-if="getData('option5', '') || optionsEdited == false">
                    <img src="~@/assets/img/template-assets/fbicon-wow-64x64.png">
                    <p>{{getData('option5', 'Killing With A Smile')}}</p>
                </li>
                <li v-if="getData('option6', '') || optionsEdited == false">
                    <img src="~@/assets/img/template-assets/fbicon-sad-64x64.png">
                    <p>{{getData('option6', 'Horizons')}}</p>
                </li>
                <li v-if="getData('option7', '')">
                    <img src="~@/assets/img/template-assets/fbicon-angry-64x64.png">
                    <p>{{getData('option7', '')}}</p>
                </li>
            </ul>
        </div>

        <img class="watermark" src="~@/assets/img/zm-logo.png">

        <div class="background" v-if="getData('showBackgroundImage', true) == true && getData('backgroundImageAspectRatio', '1.5') <= (getData('artboardX', artboardInitSize[0]) / getData('artboardY', artboardInitSize[1]))"
             :style="{backgroundImage: 'url(\'' + getData('backgroundImage', require('@/assets/img/template-assets/pollbackground-placeholder.jpg')) + '\')',
                     backgroundSize: getData('scale', 100) + '%' + ' auto',
                     backgroundPosition: (100 - getData('backgroundXPos', 50)) + '% ' + getData('backgroundYPos', 50) + '%'}">
        </div>

        <div class="background" v-if="getData('showBackgroundImage', true) == true &&getData('backgroundImageAspectRatio', '1.5') > (getData('artboardX', artboardInitSize[0]) / getData('artboardY', artboardInitSize[1]))"
             :style="{backgroundImage: 'url(\'' + getData('backgroundImage', require('@/assets/img/template-assets/pollbackground-placeholder.jpg')) + '\')',
                     backgroundSize: 'auto ' + getData('scale', 100) + '%',
                     backgroundPosition: (100 - getData('backgroundXPos', 50)) + '% ' + getData('backgroundYPos', 50) + '%'}">
        </div>

        <img hidden id="grayscaleHelper" :src="getData('backgroundImage', require('@/assets/img/template-assets/pollbackground-placeholder.jpg'))">
    </div>
</template>

<script>
    export default {
        name: "Artboard-Poll",
        props: {
            formData: Object,
            artboardInitSize: Array
        },
        data() {
            return {
                output: null,
                optionsEdited: false
            }
        },
        methods: {
            getData(name, placeholder) {
                for(var i =0; i < this.formData.length; i++) {
                    if (this.formData[i][0] == name) {
                        if (name.slice(0, -1) == "option") {
                            this.optionsEdited = true;
                        }

                        return this.formData[i][1];
                    }
                }

                return placeholder;
            },
            async renderImage() {
                this.grayscale(()=>{
                    const comp = this;
                    this.$html2canvas( this.$refs.printMe, {
                        scale: 1
                    }).then((canvas) => {
                        var a = document.createElement('a');
                        const artboard = document.getElementById("artboard");
                        const quality = parseFloat(comp.getData('quality', '1'));

                        a.href = canvas.toDataURL( 'image/jpeg', quality);
                        a.download = 'ZM-Poll' + artboard.offsetWidth + 'x' + artboard.offsetHeight + '-' + (quality * 100) + '_'+ Date.now() + '.jpg';
                        a.click();
                        comp.$emit('finished');
                    });
                });
            },
            grayscale(_callback) {
                var img1 = document.getElementById('grayscaleHelper');
                var img2 = new Image;

                img2.src = img1.src;

                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                img2.onload = function() {
                    canvas.width = img2.width;
                    canvas.height = img2.height;
                    ctx.filter = 'grayscale(1)';
                    ctx.drawImage(img2, 0, 0, img2.width, img2.height);

                    img1.src = canvas.toDataURL('image/jpeg', 1);

                    var x = document.getElementsByClassName("background");
                    var i;
                    for (i = 0; i < x.length; i++) {
                        x[i].style.backgroundImage = 'url(\'' + canvas.toDataURL('image/jpeg', 1) + '\')';
                    }

                    _callback();
                }
            }
        },
        emits:
            ['finished']
    }
</script>

<style scoped>
    #artboard {
        width: 1080px;
        height: 1080px;
        position:absolute;
        border: 2px solid #F2F2F2;
        overflow: hidden;
        background-color: rgb(20,20,20);
    }

    .content {
        position: absolute;
        top: 40px;
        left: 40px;
        right: 40px;
        bottom: 80px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 5;
    }

    .content p,
    .content ul li {
        font-family: "GillSansC";
        text-transform: uppercase;
        font-weight: bold;
        color: white;
    }

    .content .question {
        font-size: 60px;
        text-align: center;
        margin: 0px 40px 60px 40px;
    }

    .content ul {
        margin: 0px 150px;
    }

    .content ul li {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
    }

    .content ul li p {
        font-size: 40px;
        margin-left: 20px;
    }

    .content ul li img {
        height: 64px;
    }

    .overlay {
        background-image: url("~@/assets/img/template-assets/noise-texture.png");
        opacity: 0.5;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        z-index: 10;
        pointer-events: none;
    }

    .background {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        opacity: 0.05;
        z-index: 0;
        -webkit-filter: grayscale(1);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: grayscale(100%);
    }

    .watermark {
        position: absolute;
        width: 165px;
        bottom: 30px;
        left: 0px;
        right: 0px;
        margin: 0px auto;
        z-index: 1;
    }
</style>
<template>
    <select class="form-control"
            v-on:change="$emit('changeTemplate', $event.target.value)">
        <option v-for="option in content.options" :value="option.value" :key="option">{{option.text}}</option>
    </select>
</template>

<script>
    export default {
        name: "TemplateSelector",
        props: {
            content: Object
        },
        data() {
            return {
                value: ''
            }
        },
        emits:
            ['changeTemplate']
    }
</script>

<style>

</style>
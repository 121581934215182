<template>
    <div ref="printMe" id="artboard">
        <div class="overlay"></div>
        <img class="ZMlogo" src="~@/assets/img/zm-logo.png">
        <div class="winBanner">
            <div class="win">Win</div>
            <div class="win">Win</div>
            <div class="win">Win</div>
        </div>
        <div class="location" :class="{ large: (getData('venue', '') != '' && getData('city', '') != '')}"
             v-if="getData('venue', '') != '' || getData('city', '') != ''">
            <div class="mark">
                <img src="~@/assets/img/template-assets/locationMarker.png">
            </div>
            <p>{{getData('venue', '')}}<span v-if="getData('venue', '' != '') && getData('city', '' != '')">,</span></p>
            <p>{{getData('city', '')}}</p>
        </div>

        <div class="background" v-if="getData('backgroundImageAspectRatio', '1.5') <= (980 / 680)"
             :style="{backgroundImage: 'url(\'' + getData('backgroundImage', require('@/assets/img/template-assets/giveaway-placeholder.jpg')) + '\')',
                     backgroundSize: getData('scale', 100) + '%' + ' auto',
                     backgroundPosition: (100 - getData('backgroundXPos', 50)) + '% ' + getData('backgroundYPos', 50) + '%'}">
        </div>

        <div class="background" v-if="getData('backgroundImageAspectRatio', '1.5') > (980 / 680)"
             :style="{backgroundImage: 'url(\'' + getData('backgroundImage', require('@/assets/img/template-assets/giveaway-placeholder.jpg')) + '\')',
                     backgroundSize: 'auto ' + getData('scale', 100) + '%',
                     backgroundPosition: (100 - getData('backgroundXPos', 50)) + '% ' + getData('backgroundYPos', 50) + '%'}">
        </div>

        <div class="details">
            <div class="text">
                <p class="topText">{{getData('topText', '2 tickets voor')}}</p>
                <p class="bottomText">{{getData('bottomText', 'Fleddy Melculy')}}</p>
            </div>
            <div v-if="getData('dateMonth', '') != '' || getData('dateDay', '') != ''" class="calendar">
                <div class="month">
                    <p>{{getData('dateMonth', '')}}</p>
                </div>
                <div class="day">
                    <p>{{getData('dateDay', '')}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Artboard-Giveaway",
        props: {
            formData: Object,
            artboardInitSize: Array
        },
        data() {
            return {
                output: null
            }
        },
        methods: {
            getData(name, placeholder) {
                for(var i =0; i < this.formData.length; i++) {
                    if (this.formData[i][0] == name) {
                        return this.formData[i][1];
                    }
                }

                return placeholder;
            },
            async renderImage() {
                const comp = this;
                this.$html2canvas( this.$refs.printMe, {
                    scale: 1
                }).then((canvas) => {
                    var a = document.createElement('a');
                    const artboard = document.getElementById("artboard");
                    const quality = parseFloat(comp.getData('quality', '1'));

                    a.href = canvas.toDataURL( 'image/jpeg', quality);
                    a.download = 'ZM-Giveaway' + artboard.offsetWidth + 'x' + artboard.offsetHeight + '-' + (quality * 100) + '_'+ Date.now() + '.jpg';
                    a.click();
                    comp.$emit('finished');
                });
            }
        },
        emits:
            ['finished']
    }
</script>

<style scoped>
    #artboard {
        width: 1080px;
        height: 1080px;
        position:absolute;
        border: 2px solid #F2F2F2;
        overflow: hidden;
        background-color: rgb(20,20,20);
    }

    .ZMlogo {
        width: 560px;
        display: block;
        margin: 30px auto;
    }

    .winBanner {
        background-color: rgb(255,0,0);
        position: absolute;
        padding: 5px 50px;
        top: 100px;
        left: 100px;
        transform: translate(-50%, -50%) rotate(-45deg);
        z-index: 10;
    }

    .winBanner .win {
        color: #ffffff;
        display: inline-block;
        margin: 0px 12px;

        font-size: 40px;
        font-family: "GillSansC";
        text-transform: uppercase;
        font-weight: bold;
    }

    .overlay {
        background-image: url("~@/assets/img/template-assets/noise-texture.png");
        opacity: 0.5;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        z-index: 10;
        pointer-events: none;
    }

    .background {
        width: 980px;
        height: 680px;
        top: 125px;
        margin: 0px auto;
        background-position: center;
        background-size: cover;
    }

    .location {
        background-color: rgb(100,100,100);
        position: absolute;
        right: 25px;
        bottom: 250px;
        z-index: 10;
        padding: 15px 30px 15px 42px;
    }

    .location.large {
        padding-left: 50px;
    }

    .location .mark {
        position: absolute;
        left: 0px;
        width: auto;
        top: -20px;
        bottom: -20px;
        -webkit-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        transform: translate(-50%, 0%);
    }

    .location .mark img {
        height: 100%;
    }

    .location p,
    .location p span {
        font-family: "GillSansC";
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
        font-size: 25px;
    }

    .details {
        position: absolute;
        left: 50px;
        right: 50px;
        top: 855px;
        bottom: 50px;
        display: flex;
        align-items: center;
    }

    .details .text {
        flex-basis: 100%;
        padding: 20px;
    }

    .details .text p {
        text-align: center;

        font-family: "GillSansC";
        text-transform: uppercase;
        font-weight: bold;
        line-height: 1;
    }

    .details .text .topText {
        font-size: 40px;
        margin-bottom: 10px;
    }

    .details .text .bottomText {
        font-size: 80px;
    }

    .details .calendar {
        height: 150px;
        flex-basis: 200px;
        background-color: yellow;
        text-align: center;
        border-radius: 10px;
        overflow: hidden;
        display: block;
    }

    .details .calendar .month {
        height: 50px;
        background-color: red;
    }

    .details .calendar .month p {
        font-size: 25px;
        color: #ffffff;
    }

    .details .calendar div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .details .calendar div p {
        font-family: "GillSansC";
        text-transform: uppercase;
        font-weight: bold;
    }

    .details .calendar .day {
        height: 100px;
        background-color: #ffffff;
    }

    .details .calendar .day p {
        font-size: 80px;
        color: #000000;
    }
</style>
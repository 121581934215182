<template>
    <LargeHeader />
    <div id="content">
        <h1>Oops... page not found</h1>
        <p>The page you are looking for doesn't exist (anymore).</p>
        <p>Please check the URL or go back to the homepage.</p>
        <router-link to="/">
            <div class="btn btn-primary">
                Back to home
            </div>
        </router-link>
    </div>
</template>

<script>
    import LargeHeader from "@/components/LargeHeader";

    export default {
        name: "NotFound",
        components: {
            LargeHeader
        }
    }
</script>

<style scoped>
    h1 {
        font-size: 20px;
    }

    #content {
        margin: 0px auto;
        padding: 20px;
        max-width: 800px;
        padding-bottom: 100px;
    }

    p {
        color: #9b9b9b;
    }

    .btn {
        margin-top: 20px;
        display: inline-block;
    }
</style>
<template>
    <router-view></router-view>
    <p class="copyright">Copyright &copy; Niek van de Vondervoort {{ new Date().getFullYear() }}</p>
</template>

<script>
    export default {
        name: 'App',
        components: {
        }
    }
</script>

<style>
    * {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
        font-size: 14px;
    }

    .row {
        margin-left: 0px!important;
        margin-right: 0px!important;
    }

    .align-items-center {
        align-items:center
    }

    .justify-content-center {
        justify-content: center;
    }

    body {
        background-color: #161616;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: "GillSansC";
        color: #f2f2f2;
    }

    p, input, textarea, select {
        font-family: "Open Sans", sans-serif;
        color: #f2f2f2;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    label {
        margin-right: 10px;
        font-family: "Open Sans";
        color: #9b9b9b;
        font-size: 12px;
    }

    .btn {
        font-family: "GillSansC", sans-serif;
        padding: 6px;
        border-radius: 5px;
        cursor: pointer;
    }

    .btn-primary {
        background-color: #f2f2f2;
        color: black;
    }

    .btn-secondary {
        border: 2px solid #f2f2f2;
        background-color: rgba(255,255,255,0.25);
    }

    .copyright {
        font-size: 10px;
        opacity: 0.1;
        font-family: "Open Sans";
        text-align: center;
        padding-bottom: 10px;
    }

    main {
        padding: 15px;
    }

    @font-face {
        font-family: "GillSansC";
        font-weight: 700;
        font-style: normal;
        font-display: auto;
        unicode-range: U+000-5FF;
        src: url('/fonts/GillSansC/gillsansc-bold-webfont.woff2') format('woff2'),
             url('/fonts/GillSansC/gillsansc-bold-webfont.woff') format('woff'),
             url('/fonts/GillSansC/gillsansc-bold-webfont.ttf')  format('truetype')
    }
</style>

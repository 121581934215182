<template>
    <div class="slider">
        <label :ondblclick="resetValue">{{ content.label }}</label>
        <input ref="input" type="range"
               :min="content.min"
               :max="content.max"
               :value="content.value"
               v-on:change="$emit('updateInput', content.name, $event.target.value )">
    </div>
</template>

<script>
    export default {
        name: "RangeSlider",
        props: {
            content: Object
        },
        data() {
            return {
                value: ''
            }
        },
        methods: {
            resetValue() {
                this.$refs.input.value = this.content.value;
                this.$emit('updateInput', this.content.name, this.content.value);
            }
        },
        emits:
            ['updateInput']
    }
</script>

<style>
    .slider {
        display: flex;
        margin-bottom: 5px;
    }

    label {
        user-select: none;
    }

    .slider label {
        flex-basis: 140px;
        margin-right: 10px;
        font-family: "Open Sans";
        color: #9b9b9b;
        font-size: 12px;
    }

    .slider input {
        outline: none;
        flex-basis: 100%;
    }
</style>
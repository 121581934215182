<template>
    <input type="file" accept="image/*" @change="onFileChange" id="actual-btn"/>
    <label class="btn btn-primary" id="visible-btn" for="actual-btn">Upload file</label>
</template>

<script>
    export default {
        name: "FileUpload",
        props: {
            content: Object
        },
        data() {
            return {
                url: null
            }
        },
        methods: {
            onFileChange(e) {
                const file = e.target.files[0];
                this.url = URL.createObjectURL(file);
                this.$emit('updateInput', this.content.name, this.url);

                var img = new Image();
                var comp = this;
                img.onload = function() {
                    comp.$emit('updateInput', comp.content.name + 'AspectRatio', img.width / img.height);
                }
                img.src = this.url;
            }
        },
        emits:
            ['updateInput']
    }
</script>

<style scoped>
    #actual-btn  {
        display: none;
    }

    #visible-btn {
        display: table;
        margin-bottom: 14px;
    }
</style>
<template>
    <div class="colorSelector">
        <label class="hint">Color:</label>
        <div class="input">
            <input type="color"
                   v-on:change="$emit('updateInput', content.name, $event.target.value)"
                   v-model="colorValue"
                   :id="id"
                   :disabled="disabled == true">
        </div>
        <input type="text" class="extra"
               v-on:change="$emit('updateInput', content.name, $event.target.value)"
               v-model="colorValue">
    </div>
</template>

<script>
    export default {
        name: "ColorSelector",
        props: {
            content: Object,
            text: String,
            disabled: Boolean,
            id: String
        },
        data() {
            return {
                colorValue: ''
            }
        },
        mounted: function() {
            this.colorValue = this.content.value;
        },
        emits:
            ['updateInput']
    }
</script>

<style scoped>
    .colorSelector {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    label {
        margin-bottom: 0px;
    }

    .input {
        overflow: hidden;
        width: 64px;
        height: 25px;
        border-radius: 5px;
    }

    .extra {
        margin-bottom: 0px;
        margin-left: 5px;
        width: 100px;
        font-size: 12px;
        color: #9b9b9b;

        background-color: inherit;
        border: inherit;
    }

    input[type="color"] {
        -webkit-appearance: none;
        border: none;
        width: 64px;
        height: 25px;
    }
    input[type="color"]::-webkit-color-swatch-wrapper {
        padding: 0;
    }
    input[type="color"]::-webkit-color-swatch {
        border: none;
    }
</style>
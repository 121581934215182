<template>
    <div ref="printMe" class="announcement-1000x250" id="artboard">
        <div class="overlay"></div>
        <div class="content">
            <div class="text">
                <p class="albumOfTheMonth">Album van de maand</p>
                <p class="month">{{ getData('dateMonth', 'Januari') + " " + getData('dateYear', '2022') }}</p>
                <p class="stemNu announcement"  v-if="!getData('winner', '')">Stem nu!</p>
                <p class="stemNu announcement"  v-if="getData('winner', '')">De uitslag</p>
            </div>
            <div class="albumCover"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Artboard-AlbumOfTheMonth",
        props: {
            formData: Object
        },
        data() {
            return {
                output: null,
                largeTitle: false
            }
        },
        methods: {
            getData(name, placeholder) {
                for(var i =0; i < this.formData.length; i++) {
                    if (this.formData[i][0] == name) {
                        return this.formData[i][1];
                    }
                }

                return placeholder;
            },
            async renderImage() {
                const comp = this;
                this.$html2canvas( this.$refs.printMe, {
                    scale: 1
                }).then((canvas) => {
                    var a = document.createElement('a');
                    const artboard = document.getElementById("artboard");
                    const quality = parseFloat(comp.getData('quality', '1'));

                    a.href = canvas.toDataURL( 'image/jpeg', quality);
                    a.download = 'ZM-AlbumReview' + artboard.offsetWidth + 'x' + artboard.offsetHeight + '-' + (quality * 100) + '_'+ Date.now() + '.jpg';
                    a.click();
                    comp.$emit('finished');
                });
            }
        },
        emits:
            ['finished']
    }
</script>

<style scoped>
    .overlay {
        background-image: url('~@/assets/img/template-assets/noise-texture.png');
        opacity: .5;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        pointer-events: none;
    }

    p {
        font-family: GillSansC;
        text-transform: uppercase;
        font-weight: 700;
    }

    #artboard {
        width: 1000px;
        height: 250px;
        position:absolute;
        border: 2px solid #F2F2F2;
        overflow: hidden;
        background-color: rgb(20,20,20);
    }

    .albumCover {
        width: 200px;
        height: 200px;
        background-size: cover;
        background-position: center;
        position: relative;
    }

    .content {
        display: flex;
        justify-content: right;
        align-items: center;
        margin: 0px 50px;
        height: 100%;
    }

    .announcement-624x266 .content {
        justify-content: center;
        margin: 0px 0px;
        flex-direction: row-reverse;
    }

    .text {
        width: 340px;
        position: relative;
        text-align: center;
        margin: 0px 10px;

        font-family: "GillSansC";
        text-transform: uppercase;
        font-weight: bold;
        font-size: 40px;
    }

    .text p {
        font-size: 18px;
        line-height: 1.1;
    }

    .text .month {
        margin-bottom: 10px;
        font-size: 40px;
        color: #ff0000;
    }

    .announcement-1080x1080 .albumCover {
        position: absolute;
        width: 60%;
        height: 60%;
        left: 20%;
        top: 242px;
        margin: 0px auto;
    }

    .announcement-1080x1080 .text {
        margin-left: 0px;
        width: 100%;
    }

    .announcement-1080x1080 .text p {
        position: absolute;
        left: 150px;
        right: 150px;
    }

    .announcement-1080x1080 .albumOfTheMonth {
        top: 80px;
        font-size: 30px;
    }

    .announcement-1080x1080 .month {
        top: 120px;
        font-size: 80px;
    }

    .announcement-1080x1080 .stemNu {
        top: 920px;
        font-size: 60px;
    }

    .announcement-1080x1080 .content {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        display: block;
        margin: 0px;
    }

    .albumCover {
        background-image: url('~@/assets/img/template-assets/albumofthemonth-placeholder.jpg')!important;
    }
</style>
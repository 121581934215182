<template>
    <div class="formGroup" v-for="formGroup in form" :key="formGroup.name">
        <label class="name">{{ formGroup.name }}</label>
        <div v-for="formItem in formGroup.formInputs" :key="formItem.name">
            <div v-if="formItem.type == 'text'">
                <TextInput v-on:updateInput="updateInput" :content="formItem" />
            </div>
            <div v-if="formItem.type == 'photoCredits'">
                <TextInput v-on:updateInput="updateInput"
                           :disabled="photoCreditsLocked"
                           :id="'photoCredits'"
                           :content="formItem" />
            </div>
            <div v-if="formItem.type == 'textarea'">
                <TextArea v-on:updateInput="updateInput" :content="formItem" />
            </div>
            <div v-if="formItem.type == 'number'">
                <NumberInput v-on:updateInput="updateInput" :content="formItem" />
            </div>
            <div v-if="formItem.type == 'range'">
                <RangeSlider v-on:updateInput="updateInput" :content="formItem" />
            </div>
            <div v-if="formItem.type == 'select'">
                <Select v-on:updateInput="updateInput" :content="formItem" />
            </div>
            <div v-if="formItem.type == 'checkbox'" >
                <CheckboxInput v-on:updateInput="updateInput" :content="formItem" />
            </div>
            <div v-if="formItem.type == 'upload'" >
                <FileUpload v-on:updateInput="updateInput" :content="formItem"/>
            </div>
            <div v-if="formItem.type == 'chooseimage'" >
                <ChooseImage v-on:updateInput="updateInput" v-on:setPhotoCreditsLocked="setPhotoCreditsLocked" :content="formItem"/>
            </div>
            <div v-if="formItem.type == 'formatselector'" >
                <TemplateSelector v-on:changeTemplate="changeTemplate" :content="formItem"/>
            </div>
            <div v-if="formItem.type == 'colorSelector'" >
                <colorSelector v-on:updateInput="updateInput" :content="formItem"/>
            </div>
            <div v-if="formItem.type == 'qualitySelector'" >
                <QualitySelector v-on:updateInput="updateInput" :content="formItem"/>
            </div>
        </div>
    </div>
</template>
<script>
    import TextInput from "@/components/Form/TextInput";
    import TextArea from "@/components/Form/TextArea";
    import Select from "@/components/Form/Select";
    import NumberInput from "@/components/Form/NumberInput";
    import RangeSlider from "@/components/Form/RangeSlider";
    import CheckboxInput from "@/components/Form/CheckboxInput";
    import FileUpload from "@/components/Form/FileUpload";
    import ChooseImage from "@/components/Form/ChooseImage";
    import ColorSelector from "@/components/Form/ColorSelector";
    import TemplateSelector from "@/components/Form/TemplateSelector";
    import QualitySelector from "@/components/Form/QualitySelector";

    export default {
        name: "Form",
        components: {
            TextInput,
            TextArea,
            Select,
            NumberInput,
            RangeSlider,
            CheckboxInput,
            FileUpload,
            ChooseImage,
            ColorSelector,
            TemplateSelector,
            QualitySelector
        },
        props: {
            form: Object
        },
        data: function () {
            return {
                formData: [],
                url: null,
                photoCreditsLocked: false
            }
        },
        methods: {
            updateInput (name, value) {
                var exists = false;

                for (var i = 0; i < this.formData.length; i++) {
                    if (this.formData[i][0] == name)
                    {
                        exists = true;
                        this.formData[i][1] = value;
                        this.$emit('updateFormData', this.formData);
                        break;
                    }
                }

                if (!exists) {
                    this.formData.push([name,value]);
                    this.$emit('updateFormData', this.formData);
                }
            },
            changeTemplate (value) {
                this.$emit('changeTemplate', value);

                const obj = JSON.parse(value);
                this.updateInput('artboardX', obj.x);
                this.updateInput('artboardY', obj.y);
            },
            setPhotoCreditsLocked (value) {
                this.photoCreditsLocked = value;
            }
        },
        emits:
            ['updateFormData', 'changeTemplate']
    }
</script>

<style>
    .formGroup {
        padding: 15px 10px 0px 10px;
        border: 1px solid rgb(80,80,80);
        border-radius: 5px;
        background-color: rgb(30,30,30);
        position: relative;
    }

    .formGroup:not(:last-child) {
        margin-bottom: 20px;
    }

    .formGroup .name {
        position: absolute;
        left: 10px;
        top: -10px;
    }

    .form-control {
        width: 100%;
        background-color: #000;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        border: 1px solid #808080;
        color: #afafaf;
        padding: 5px;
        margin-bottom: 10px;
    }

    .form-control:disabled {
        color: rgba(175,175,175,0.25);
        cursor: not-allowed;
    }

    .hint {
        display: inline-block;
        margin-bottom: 5px;
        font-size: 12px;
        color: #9b9b9b;
    }

    .required {
        color: #ff0000;
    }
</style>
<template>
    <div ref="printMe" id="artboard" class="spotifyPlaylist-1200x1200">
        <div class="background" v-if="getData('backgroundImageAspectRatio', '1.5') <= (getData('artboardX', artboardInitSize[0]) / getData('artboardY', artboardInitSize[1]))"
             :style="{backgroundImage: 'url(\'' + getData('backgroundImage', require('@/assets/img/template-assets/spotifyplaylist-placeholder.jpg')) + '\')',
                     backgroundSize: getData('scale', 100) + '%' + ' auto',
                     backgroundPosition: (100 - getData('backgroundXPos', 50)) + '% ' + getData('backgroundYPos', 50) + '%'}">
        </div>

        <div class="background" v-if="getData('backgroundImageAspectRatio', '1.5') > (getData('artboardX', artboardInitSize[0]) / getData('artboardY', artboardInitSize[1]))"
             :style="{backgroundImage: 'url(\'' + getData('backgroundImage', require('@/assets/img/template-assets/spotifyplaylist-placeholder.jpg')) + '\')',
                     backgroundSize: 'auto ' + getData('scale', 100) + '%',
                     backgroundPosition: (100 - getData('backgroundXPos', 50)) + '% ' + getData('backgroundYPos', 50) + '%'}">
        </div>
        <div class="slope" :style="'background-color: ' + getData('backdropColor', '#0856a2')"></div>
        <div class="backdrop" :style="'background-color: ' + getData('backdropColor', '#0856a2')"></div>
        <div class="title">
            <p>
                {{ getData('playlistTitle', 'Old school death metal') }}
            </p>
        </div>
        <img class="watermark" src="~@/assets/img/template-assets/zm-icon.png">
    </div>
</template>

<script>
    export default {
        name: "Artboard-SpotifyPlaylist",
        props: {
            formData: Object,
            artboardInitSize: Array
        },
        data() {
            return {
                output: null
            }
        },
        methods: {
            getData(name, placeholder) {
                for(var i =0; i < this.formData.length; i++) {
                    if (this.formData[i][0] == name) {
                        return this.formData[i][1];
                    }
                }

                return placeholder;
            },
            async renderImage() {
                const comp = this;
                this.$html2canvas( this.$refs.printMe, {
                    scale: 1
                }).then((canvas) => {
                    var a = document.createElement('a');
                    const artboard = document.getElementById("artboard");
                    const quality = parseFloat(comp.getData('quality', '1'));

                    a.href = canvas.toDataURL( 'image/jpeg', quality);
                    a.download = 'ZM-SpotifyPlaylist' + artboard.offsetWidth + 'x' + artboard.offsetHeight + '-' + (quality * 100) + '_'+ Date.now() + '.jpg';
                    a.click();
                    comp.$emit('finished');
                });
            }
        },
        emits:
            ['finished']
    }
</script>

<style scoped>
    #artboard {
        width: 1200px;
        height: 1200px;
        position:absolute;
        border: 2px solid #F2F2F2;
        overflow: hidden;
    }

    .background {
        position: absolute;
        background-size: cover;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 24%;
    }

    .title {
        margin: 50px;
        text-align: center;
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .title p {
        font-family: GillSansC;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 100px;
    }

    .slope{
        position: absolute;
        bottom: 10%;
        left: 0px;
        right: 0px;
        transform:skewY(-6deg);

        background: #0856a2;
        height: 240px;
    }

    .backdrop {
        position: absolute;
        top: 78%;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background: #0856a2;
    }

    .watermark {
        position: absolute;
        width: 125px;
        top: 50px;
        right: 40px;
    }
</style>
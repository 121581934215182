<template>
    <header>
        <img class="logo" src="~@/assets/img/zm-logo.png">
        <div class="overlay"></div>
    </header>
</template>

<script>
    export default {
        name: "LargeHeader"
    }
</script>

<style scoped>
    header {
        background-color: blue;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url("~@/assets/img/homepageheader.jpg");
        background-size: 100%;
        background-position: center;
        position: relative;
    }

    header .logo {
        width: 80%;
        max-width: 500px;
        margin: 0px auto;
        z-index: 5;
    }

    header .overlay {
        height: 100%;
        width: 100%;
        position: absolute;
        bottom: 0px;
        background: linear-gradient(rgba(22,22,22,0.666) 0%, rgba(22,22,22,0.666) 50%, rgba(22,22,22,1) 100%);
    }
</style>
<template>
    <div ref="printMe" id="artboard" class="darkWickPick-1080x1080">
        <div class="contentArea">
            <div :style="{ width: getData('width', 75) + '%' }"
                 v-html="headlineFormatting(getData('content', 'Welke angsten houden je s\'nachts wakker?')).outerHTML">
            </div>
        </div>
        <p v-if="getData('credits', '') != ''"
           class="credits">Suggestie: {{ getData('credits', '') }}</p>
        <div class="background"></div>
    </div>
</template>

<script>
    export default {
        name: "Artboard-DarkWickpick",
        props: {
            formData: Object,
            artboardInitSize: Array
        },
        data() {
            return {
                output: null
            }
        },
        methods: {
            getData(name, placeholder) {
                for(var i =0; i < this.formData.length; i++) {
                    if (this.formData[i][0] == name) {
                        console.log(this.formData[i][1]);
                        return this.formData[i][1];
                    }
                }

                return placeholder;
            },
            headlineFormatting(text) {
                var p = document.createElement("p");
                p.classList.add("darkWickPick");

                p.innerText = text;
                return p;
            },
            async renderImage() {
                const comp = this;
                this.$html2canvas( this.$refs.printMe, {
                    scale: 1
                }).then((canvas) => {
                    var a = document.createElement('a');
                    const artboard = document.getElementById("artboard");
                    const quality = parseFloat(comp.getData('quality', '1'));

                    a.href = canvas.toDataURL( 'image/jpeg', quality);
                    a.download = 'DarkWickPick' + artboard.offsetWidth + 'x' + artboard.offsetHeight + '-' + (quality * 100) + '_'+ Date.now() + '.jpg';
                    a.click();
                    comp.$emit('finished');
                });
            }
        },
        emits:
            ['finished']
    }
</script>

<style scoped>
    #artboard {
        width: 1080px;
        height: 1080px;
        position:absolute;
        border: 2px solid #F2F2F2;
        overflow: hidden;
    }

    @font-face {
        font-family: "Myriad-Pro-Regular";
        src: url("~@/assets/woff/Myriad-Pro-Regular.woff") format('woff'),
             url("~@/assets/otf/Myriad-Pro-Regular.otf") format('otf'),
             url('~@/assets/ttf/Myriad-Pro-Regular.ttf')  format('truetype');
    }

    .contentArea {
        position: absolute;
        top: 175px;
        left: 123px;
        right: 123px;
        bottom: 165px;
        z-index: 10;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .credits {
        color: #ED1C24;
        position: absolute;
        text-align: center;
        z-index: 10;
        font-size: 30px;
        bottom: 220px;
        left: 200px;
        right: 200px;
        font-weight: 400;
        font-family: Myriad-Pro-Regular;
    }

    .background {
        position: absolute;
        background-size: cover;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-image: url("~@/assets/img/template-assets/darkwickpick-bg.jpg");
    }
</style>
<style>
    .darkWickPick {
        color: #ED1C24;
        position: relative;
        text-align: center;
        font-size: 61px;
        font-weight: 400;
        font-family: Myriad-Pro-Regular;
    }
</style>
<template>
    <div ref="printMe" id="artboard">
        <div class="overlay"></div>
        <div class="linkInBio" v-if="getData('linkInBio',false)">
            <p>Link in Bio</p>
        </div>
        <div id="newsHeadline" v-html="headlineFormatting(getData('headline', '[Steel Panther] verder zonder Lexxi Foxx')).outerHTML">
        </div>
        <div class="photocredits">
            <p v-if="getData('photoCredits', '') != ''">Foto:</p>
            <p v-if="getData('photoCredits', '') != '' &&
                getData('photoCredits', '') != 'niekvdv' &&
                getData('photoCredits', '') != 'niek vdv' &&
                getData('photoCredits', '') != 'niek van de vondervoort' &&
                getData('photoCredits', '') != 'niek vd vondervoort'  &&
                getData('photoCredits', '') != 'niekvandevondervoort.com'"
               class="source">{{getData('photoCredits', '')}}</p>
        <div v-if="getData('photoCredits', '') == 'niekvdv' ||
            getData('photoCredits', '') == 'niek vdv' ||
            getData('photoCredits', '') == 'niek van de vondervoort' ||
            getData('photoCredits', '') == 'niek vd vondervoort'  ||
            getData('photoCredits', '') == 'niekvandevondervoort.com'" class="logo"></div>
        </div>
        <img class="watermark" src="~@/assets/img/zm-logo.png">

        <div class="background" v-if="getData('backgroundImageAspectRatio', '1.5') <= (getData('artboardX', artboardInitSize[0]) / getData('artboardY', artboardInitSize[1]))"
             :style="{backgroundImage: 'url(\'' + getData('backgroundImage', require('@/assets/img/template-assets/newsbackground-placeholder.jpg')) + '\')',
                     backgroundSize: getData('scale', 100) + '%' + ' auto',
                     backgroundPosition: (100 - getData('backgroundXPos', 50)) + '% ' + getData('backgroundYPos', 50) + '%'}">
        </div>

        <div class="background" v-if="getData('backgroundImageAspectRatio', '1.5') > (getData('artboardX', artboardInitSize[0]) / getData('artboardY', artboardInitSize[1]))"
             :style="{backgroundImage: 'url(\'' + getData('backgroundImage', require('@/assets/img/template-assets/newsbackground-placeholder.jpg')) + '\')',
                     backgroundSize: 'auto ' + getData('scale', 100) + '%',
                     backgroundPosition: (100 - getData('backgroundXPos', 50)) + '% ' + getData('backgroundYPos', 50) + '%'}">
        </div>

        <div class="gradient"></div>
        <div></div>

        <img hidden id="grayscaleHelper" :src="getData('backgroundImage', require('@/assets/img/template-assets/newsbackground-placeholder.jpg'))">
    </div>
</template>

<script>
    export default {
        name: "Artboard-NewsArticle",
        props: {
            formData: Object,
            artboardInitSize: Array
        },
        data() {
            return {
                output: null
            }
        },
        methods: {
            getData(name, placeholder) {
                for(var i =0; i < this.formData.length; i++) {
                    if (this.formData[i][0] == name) {
                        return this.formData[i][1];
                    }
                }

                return placeholder;
            },
            async renderImage() {
                this.grayscale(()=>{
                    const comp = this;
                    this.$html2canvas( this.$refs.printMe, {
                        scale: 1
                    }).then((canvas) => {
                        var a = document.createElement('a');
                        const artboard = document.getElementById("artboard");
                        const quality = parseFloat(comp.getData('quality', '1'));

                        a.href = canvas.toDataURL( 'image/jpeg', quality);
                        a.download = 'ZM-NewsArticle' + artboard.offsetWidth + 'x' + artboard.offsetHeight + '-' + (quality * 100) + '_'+ Date.now() + '.jpg';
                        a.click();
                        comp.$emit('finished');
                    });
                });
            },
            headlineFormatting(text) {
                var var1 = text.substr(0, text.indexOf('['));
                var var2 = text.substr(text.indexOf('[') + 1, text.length);
                var var3 = var2.substr(var2.indexOf(']') + 1, var2.length);

                var2 = var2.substr(0, var2.indexOf(']'));

                var p = document.createElement("p");
                p.classList.add("text");

                if (var2 != "") {
                    var preSpan = document.createElement("span");
                    preSpan.innerText = var1;
                    var redSpan = document.createElement("span");
                    redSpan.innerText = var2;
                    redSpan.style.color = "#ff0000";
                    var afterSpan = document.createElement("span");
                    afterSpan.innerText = var3;

                    p.appendChild(preSpan);
                    p.appendChild(redSpan);
                    p.appendChild(afterSpan);

                    return p;
                } else {
                    p.innerText = text;
                    return p;
                }
            },
            grayscale(_callback) {
                var img1 = document.getElementById('grayscaleHelper');
                var img2 = new Image;

                img2.src = img1.src;

                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                img2.onload = function() {
                    canvas.width = img2.width;
                    canvas.height = img2.height;
                    ctx.filter = 'grayscale(1)';
                    ctx.drawImage(img2, 0, 0, img2.width, img2.height);

                    img1.src = canvas.toDataURL('image/jpeg', 1);

                    var x = document.getElementsByClassName("background");
                    var i;
                    for (i = 0; i < x.length; i++) {
                        x[i].style.backgroundImage = 'url(\'' + canvas.toDataURL('image/jpeg', 1) + '\')';
                    }

                    _callback();
                }
            }
        },
        emits:
            ['finished']
    }
</script>

<style scoped>
    #artboard {
        width: 1080px;
        height: 1080px;
        position:absolute;
        border: 2px solid #F2F2F2;
        overflow: hidden;
        background-color: rgb(20,20,20);
    }

    p {
        font-family: "GillSansC";
        text-transform: uppercase;
        font-weight: bold;
    }

    .overlay {
        background-image: url("~@/assets/img/template-assets/noise-texture.png");
        opacity: 0.5;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        z-index: 10;
        pointer-events: none;
    }

    .linkInBio {
        position: absolute;
        right: 15px;
        bottom: -3px;
        background-image: url("~@/assets/img/template-assets/blood-spatter-pancake.png");
        width: 260px;
        height: 215px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 1;
    }

    .linkInBio p {
        font-size: 30px;
        width: 150px;
        font-size: 35px;
        left: 75px;
        top: 74px;
        -webkit-transform: rotate(10deg);
        -moz-transform: rotate(10deg);
        -ms-transform: rotate(10deg);
        -o-transform: rotate(10deg);
        transform: rotate(10deg);
        text-align: center;
        position: absolute;
    }

    .gradient {
        width: 100%;
        height: 250px;
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(20,20,20,0) 100%);
    }

    .background {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        opacity: 0.2;
        z-index: 0;
        -webkit-filter: grayscale(1);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: grayscale(100%);
    }

    .photocredits {
        position: absolute;
        left: 69px;
        bottom: 42px;
        z-index: 1;
        display: flex;
        align-items: center;
    }

    .photocredits p {
        display: inline-block;
    }

    .photocredits .source {
        margin-left: 5px;
    }

    .photocredits .logo {
        display: inline-block;
        margin-left: 5px;
        height: 50px;
        width: 130px;
        background-image: url("~@/assets/img/template-assets/niekvandevondervoort-watermerk.png");
        background-size: contain;
        background-position: center left;
        background-repeat: no-repeat;
    }

    .watermark {
        position: absolute;
        width: 165px;
        bottom: 30px;
        left: 0px;
        right: 0px;
        margin: 0px auto;
        z-index: 1;
    }
</style>
<style>
    #newsHeadline p {
        position: relative;
        width: 70%;
        z-index: 1;
        margin: 50px;
        font-family: "GillSansC";
        text-transform: uppercase;
        font-weight: bold;
    }

    #newsHeadline p,
    #newsHeadline p span {
        font-size: 75px;
    }
</style>

<style>
    .news-1080x1920 #newsHeadline .text {
        margin-top: 200px;
    }

    .news-1080x1920 .watermark {
        bottom: 200px;
    }
</style>
<template>
    <div ref="printMe" id="artboard">
        <div class="overlay"></div>
        <div class="quizLogo">
            <img src="~@/assets/img/template-assets/zm-text.png">
            <p>Quiz</p>
        </div>

        <div class="photocredits">
            <p v-if="getData('photoCredits', '') != ''">Foto:</p>
            <p v-if="getData('photoCredits', '') != '' &&
                getData('photoCredits', '') != 'niekvdv' &&
                getData('photoCredits', '') != 'niek vdv' &&
                getData('photoCredits', '') != 'niek van de vondervoort' &&
                getData('photoCredits', '') != 'niek vd vondervoort'  &&
                getData('photoCredits', '') != 'niekvandevondervoort.com'"
               class="source">{{getData('photoCredits', '')}}</p>
            <div v-if="getData('photoCredits', '') == 'niekvdv' ||
            getData('photoCredits', '') == 'niek vdv' ||
            getData('photoCredits', '') == 'niek van de vondervoort' ||
            getData('photoCredits', '') == 'niek vd vondervoort'  ||
            getData('photoCredits', '') == 'niekvandevondervoort.com'" class="logo"></div>
        </div>

        <div class="background" v-if="getData('backgroundImageAspectRatio', '1.5') <= (1080 / 1920)"
             :style="{backgroundImage: 'url(\'' + getData('backgroundImage', require('@/assets/img/template-assets/quizbackground-placeholder.jpg')) + '\')',
                     backgroundSize: getData('scale', 100) + '%' + ' auto',
                     backgroundPosition: (100 - getData('backgroundXPos', 50)) + '% ' + getData('backgroundYPos', 50) + '%'}">
        </div>

        <div class="background" v-if="getData('backgroundImageAspectRatio', '1.5') > (1080 / 1920)"
             :style="{backgroundImage: 'url(\'' + getData('backgroundImage', require('@/assets/img/template-assets/quizbackground-placeholder.jpg')) + '\')',
                     backgroundSize: 'auto ' + getData('scale', 100) + '%',
                     backgroundPosition: (100 - getData('backgroundXPos', 50)) + '% ' + getData('backgroundYPos', 50) + '%'}">
        </div>
        <div class="gradient"></div>
    </div>
</template>

<script>
    export default {
        name: "Artboard-Quiz",
        props: {
            formData: Object,
            artboardInitSize: Array
        },
        data() {
            return {
                output: null
            }
        },
        methods: {
            getData(name, placeholder) {
                for(var i =0; i < this.formData.length; i++) {
                    if (this.formData[i][0] == name) {
                        return this.formData[i][1];
                    }
                }

                return placeholder;
            },
            async renderImage() {
                const comp = this;
                this.$html2canvas( this.$refs.printMe, {
                    scale: 1
                }).then((canvas) => {
                    var a = document.createElement('a');
                    const artboard = document.getElementById("artboard");
                    const quality = parseFloat(comp.getData('quality', '1'));

                    a.href = canvas.toDataURL( 'image/jpeg', quality);
                    a.download = 'ZM-Quiz' + artboard.offsetWidth + 'x' + artboard.offsetHeight + '-' + (quality * 100) + '_'+ Date.now() + '.jpg';
                    a.click();
                    comp.$emit('finished');
                });
            }
        },
        emits:
            ['finished']
    }
</script>

<style scoped>
    #artboard {
        width: 1080px;
        height: 1920px;
        position: absolute;
        border: 2px solid #F2F2F2;
        overflow: hidden;
        background-color: rgb(20,20,20);
    }

    .overlay {
        background-image: url("~@/assets/img/template-assets/noise-texture.png");
        opacity: 0.5;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        z-index: 10;
        pointer-events: none;
    }

    .quizLogo {
        margin-top: 150px;
        text-align: center;
        z-index: 1;
        position: relative;
    }

    .quizLogo img {
        width: 80%;
    }

    .quizLogo p {
        color: #ff0000;
        font-size: 80px;
        text-align: center;
        font-family: "GillSansC";
        text-transform: uppercase;
        font-weight: bold;
    }

    .background {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        opacity: 0.2;
        z-index: 0;
    }

    .gradient {
        width: 100%;
        height: 500px;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(20,20,20,1) 100%);
    }

    .photocredits {
        position: absolute;
        right: 69px;
        bottom: 175px;
        z-index: 1;
        display: flex;
        align-items: center;
    }

    .photocredits p {
        display: inline-block;
        font-size: 16px;
        font-family: "GillSansC";
        text-transform: uppercase;
        font-weight: bold;
    }

    .photocredits .source {
        margin-left: 5px;
    }

    .photocredits .logo {
        display: inline-block;
        margin-left: 5px;
        height: 50px;
        width: 130px;
        background-image: url("~@/assets/img/template-assets/niekvandevondervoort-watermerk.png");
        background-size: contain;
        background-position: center left;
        background-repeat: no-repeat;
    }
</style>
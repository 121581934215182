import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/flexboxgrid.min.css';
import VueHtml2Canvas from 'vue-html2canvas';

import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faArrowLeft, faDownload);

createApp(App)
    .use(router)
    .use(VueHtml2Canvas)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount('#app')
<template>
        <div class="cards" :key="template.id" v-for="template in visibleTemplates">
            <Card :template="template" />
        </div>
</template>

<script>
    import Card from "@/components/Card.vue"

    export default {
        name: "Cards",
        components: {
            Card
        },
        props: {
            templates: Array,
        },
        computed: {
            visibleTemplates: function () {
                return this.templates.filter(function (template) {
                    return template.visible
                })
            }
        }
    }
</script>

<style scoped>
    .cards {
        width: 100%;
    }
</style>
<template>
    <router-link :to="{path: '/editor/' + template.artboard}">
        <div class="card">
            <div class="card-left">
                <div class="card-thumbnail"
                     :style="{'background-image': 'url(' + require('@/assets/img/preview/' + template.artboard + '.jpg') + ')'}">
                </div>
            </div>
            <div class="card-right">
                <p class="card-title">{{ template.title }}</p>
                <p class="card-formats">
                    <span v-for="template in template.templates" :key="template">
                       {{ template.x }}x{{ template.y }}
                    </span>
                </p>
            </div>
        </div>
    </router-link>
</template>

<script>
    export default {
        name: "Card",
        props: {
            title: String,
            template: Object
        }
    }
</script>

<style scoped>
    a {
        text-decoration: none;
        color: inherit;
    }

    .card {
        background-color: #2b2b2b;
        border-radius: 5px;
        padding: 7px;
        display: flex;
        margin-bottom: 10px;
    }
    .card-thumbnail {
        width: 70px;
        height: 70px;
        background-size: cover;
        background-position: center;
    }

    .card-right {
        margin-left: 10px;
    }

    .card-formats span {
        padding: 2px 4px;
        border-radius: 5px;
        font-size: 10px;
        background-color: rgb(70,70,70);
        color: rgb(140,140,140);
        margin-right: 3px;
        font-weight: bold;
    }

    .card-title {
        font-weight: bold;
    }
</style>
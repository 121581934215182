<template>
    <div ref="printMe" id="artboard">

        <div class="background" v-if="getData('backgroundImageAspectRatio', '1.5') <= (getData('artboardX', artboardInitSize[0]) / getData('artboardY', artboardInitSize[1]))"
             :style="{backgroundImage: 'url(\'' + getData('backgroundImage', require('@/assets/img/template-assets/carrouselfeed-placeholder.jpg')) + '\')',
                     backgroundSize: getData('scale', 100) + '%' + ' auto',
                     backgroundPosition: (100 - getData('backgroundXPos', 50)) + '% ' + getData('backgroundYPos', 50) + '%'}">
        </div>

        <div class="background" v-if="getData('backgroundImageAspectRatio', '1.5') > (getData('artboardX', artboardInitSize[0]) / getData('artboardY', artboardInitSize[1]))"
             :style="{backgroundImage: 'url(\'' + getData('backgroundImage', require('@/assets/img/template-assets/carrouselfeed-placeholder.jpg')) + '\')',
                     backgroundSize: 'auto ' + getData('scale', 100) + '%',
                     backgroundPosition: (100 - getData('backgroundXPos', 50)) + '% ' + getData('backgroundYPos', 50) + '%'}">
        </div>

    </div>
</template>

<script>
    export default {
        name: "Artboard_FeedCarrousel",
        props: {
            formData: Object,
            artboardInitSize: Array
        },
        data() {
            return {
                output: null
            }
        },
        methods: {
            getData(name, placeholder) {
                for(var i =0; i < this.formData.length; i++) {
                    if (this.formData[i][0] == name) {
                        return this.formData[i][1];
                    }
                }

                return placeholder;
            },
            async renderImage() {
                const comp = this;
                this.$html2canvas( this.$refs.printMe, {
                    scale: 1
                }).then((canvas) => {
                    var a = document.createElement('a');
                    const artboard = document.getElementById("artboard");
                    const quality = parseFloat(comp.getData('quality', '0.4'));
                    var name = "FeedAndCarrousel";

                    if (artboard.offsetWidth == 1000 && artboard.offsetHeight == 250) {
                        name = "Carrousel";
                    } else if (artboard.offsetWidth == 624 && artboard.offsetHeight == 266) {
                        name = "Feed";
                    }

                    a.href = canvas.toDataURL( 'image/jpeg', quality);
                    a.download = 'ZM-' + name + artboard.offsetWidth + 'x' + artboard.offsetHeight + '-' + (quality * 100) + '_'+ Date.now() + '.jpg';
                    a.click();
                    comp.$emit('finished');
                });
            },
        },
        emits:
            ['finished']
    }
</script>

<style scoped>
    #artboard {
        width: 1000px;
        height: 250px;
        position:absolute;
        border: 2px solid #F2F2F2;
        overflow: hidden;
        background-color: rgb(20,20,20);
    }

    .background {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
    }
</style>
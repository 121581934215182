<template>
    <div ref="printMe" class="album-1080x1080" id="artboard">
        <div class="overlay"></div>
        <div class="topText">
            <p class="bandName">{{getData('bandName', 'Dantalion')}}</p>
            <p ref="albumTitle" class="albumTitle">{{getData('albumTitle', 'Time To Pass Away')}}</p>
        </div>
        <div class="pancake" :class="{ small: largeTitle }">
            <div>
                <p class="score">{{getData('score', 'x')}}/100</p>
                <p class="linkInBio" v-if="getData('linkInBio', '')">Link in bio</p>
            </div>
        </div>
        <div class="quote">
            <p>"{{getData('quote', 'Prachtplaat! Black metal van het hoogste niveau!')}}"</p>
        </div>
        <div class="albumCover" :class="{ small: largeTitle }" :style="{backgroundImage: 'url(\'' + getData('albumCover', require('@/assets/img/template-assets/albumcover-placeholder.jpg')) + '\')'}">
        </div>
        <img class="albumcover-shadow" :class="{ small: largeTitle }" src="~@/assets/img/template-assets/albumcover-shadow.png">
        <img class="watermark" src="~@/assets/img/zm-logo.png">
    </div>
</template>

<script>
    export default {
        name: "Artboard-AlbumReview",
        props: {
            formData: Object
        },
        data() {
            return {
                output: null,
                largeTitle: false
            }
        },
        methods: {
            getData(name, placeholder) {
                for(var i =0; i < this.formData.length; i++) {
                    if (this.formData[i][0] == name) {
                        if (name == "albumTitle") {
                            this.checkLargeTitle();
                        }

                        return this.formData[i][1];
                    }
                }


                return placeholder;
            },
            async renderImage() {
                const comp = this;
                this.$html2canvas( this.$refs.printMe, {
                    scale: 1
                }).then((canvas) => {
                    var a = document.createElement('a');
                    const artboard = document.getElementById("artboard");
                    const quality = parseFloat(comp.getData('quality', '1'));

                    a.href = canvas.toDataURL( 'image/jpeg', quality);
                    a.download = 'ZM-AlbumReview' + artboard.offsetWidth + 'x' + artboard.offsetHeight + '-' + (quality * 100) + '_'+ Date.now() + '.jpg';
                    a.click();
                    comp.$emit('finished');
                });
            },
            checkLargeTitle() {
                console.log(this.$refs.albumTitle.offsetHeight);

                if (this.$refs.albumTitle.offsetHeight > 100 && this.largeTitle == false) {
                    this.largeTitle = true;
                } else if (this.$refs.albumTitle.offsetHeight < 100 && this.largeTitle == true) {
                    this.largeTitle = false;
                }
            }
        },
        emits:
            ['finished']
    }
</script>

<style scoped>
    .overlay {
        background-image: url('~@/assets/img/template-assets/noise-texture.png');
        opacity: .5;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        pointer-events: none;
    }

    .topText {
        position: absolute;
        top: 45px;
        left: 50px;
        right: 50px;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .topText .bandName {
        text-align: center;
        color: red;
        font-size: 40px;
        flex-basis: 100%;
    }

    .topText .albumTitle {
        margin-top: 5px;
        text-align: center;
        font-size: 75px;
        flex-basis: 100%;
    }

    p {
        font-family: GillSansC;
        text-transform: uppercase;
        font-weight: 700;
    }

    .pancake {
        position: absolute;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        background-color: #000;
        right: 70px;
        top: 250px;
        transform: rotate(10deg);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        z-index: 5;
        text-align: center;
    }

    .album-1080x1080 .pancake.small {
        top: 320px;
        right: 100px;
    }

    .pancake .score {
        color: #f2f2f2;
        font-size: 50px;
    }

    .pancake .linkInBio {
        color: #f2f2f2;
        font-size: 20px;
    }

    .quote {
        position: absolute;
        left: 30px;
        right: 30px;
        height: 150px;
        bottom: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .quote p {
        font-size: 40px;
        text-align: center;
    }

    .albumCover {
        position: absolute;
        width: 55%;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 230px;
        background-size: cover;
        background-position: 50%;
        z-index: 1;
        pointer-events: none;
    }

    .album-1080x1080 .albumCover.small {
        width: 50%;
        top: 305px;
    }

    .albumCover:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    .albumcover-shadow {
        position: absolute;
        bottom: 20%;
        width: 63%;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .album-1080x1080 .albumcover-shadow.small {
        width: 57.27%;
        bottom: 18.5%;
    }

    .watermark {
        position: absolute;
        width: 165px;
        bottom: 30px;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 1;
    }

    #artboard {
        width: 1080px;
        height: 1080px;
        position:absolute;
        border: 2px solid #F2F2F2;
        overflow: hidden;
        background-color: rgb(20,20,20);
    }
</style>

<style>
    .album-1080x1920 .quote {
        bottom: 300px;
    }

    .album-1080x1920 .topText {
        top: 250px;
    }

    .album-1080x1920 .pancake {
        top: 575px;
        right: 40px;
    }

    .album-1080x1920 .albumCover {
        top: 550px;
        width: 70%;
    }

    .album-1080x1920 .albumcover-shadow {
        bottom: 565px;
        width: 80%;
    }

    .album-1080x1920 .watermark {
        bottom: 200px;
    }
</style>
import { render } from "./Artboard_NewsArticle.vue?vue&type=template&id=0db8b0ec&scoped=true"
import script from "./Artboard_NewsArticle.vue?vue&type=script&lang=js"
export * from "./Artboard_NewsArticle.vue?vue&type=script&lang=js"

import "./Artboard_NewsArticle.vue?vue&type=style&index=0&id=0db8b0ec&scoped=true&lang=css"
import "./Artboard_NewsArticle.vue?vue&type=style&index=1&id=0db8b0ec&lang=css"
import "./Artboard_NewsArticle.vue?vue&type=style&index=2&id=0db8b0ec&lang=css"
script.render = render
script.__scopeId = "data-v-0db8b0ec"

export default script
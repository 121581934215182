<template>
    <input class="form-control" type="number"
           v-on:change="$emit('updateInput', content.name, $event.target.value)"
           v-model="value"
           :placeholder="content.label"
           :min="content.min"
           :max="content.max">
</template>

<script>
    export default {
        name: "NumberInput",
        props: {
            content: Object
        },
        data() {
            return {
                value: ''
            }
        },
        emits:
            ['updateInput']
    }
</script>

<style>

</style>
<template>
    <p class="hint">{{content.hint}}</p>
    <textarea class="form-control"
              :placeholder="content.placeholder"
              v-on:keyup="$emit('updateInput', content.name, $event.target.value)"
              v-model="value"></textarea>
</template>

<script>
    export default {
        name: "TextArea",
        props: {
            content: Object
        },
        data() {
            return {
                value: ''
            }
        },
        emits:
            ['updateInput']
    }
</script>

<style scoped>
    textarea {
        resize: vertical;
        height: 100px;
    }
</style>